import { createHttpEndpoint } from '../../utils'

import type {
  MoneyOut,
  MoneyOutSalesAmount,
  PostMoneyOutResponse,
} from './money-out.types'

export const getMoneyouts = createHttpEndpoint<MoneyOut[]>({
  method: 'GET',
  operationId: 'getMoneyouts',
  path: '/bm/client/moneyouts',
})

export const getMoneyOutSalesAmount = createHttpEndpoint<MoneyOutSalesAmount>({
  method: 'GET',
  operationId: 'getMoneyOutSalesAmount',
  path: '/bm/client/wallet',
})

export const postMoneyout = createHttpEndpoint<PostMoneyOutResponse>({
  method: 'POST',
  operationId: 'postMoneyout',
  path: '/dashboard/others/moneyout',
})

<template>
  <RevCard
    class="grid flex-wrap items-center justify-center gap-4 p-32 md:flex md:justify-between"
  >
    <div class="max-md:mx-auto">
      <p class="body-1">{{ i18n(translations.creditTitle) }}</p>

      <RevSkeleton v-if="pending" height="1lh" shape="rectangle" />
      <p v-else class="heading-2">{{ data ? i18n.price(data.amount) : '' }}</p>
    </div>

    <MoneyoutAction v-if="Number(data?.amount.amount ?? '0') > 0" />
  </RevCard>
</template>

<script setup lang="ts">
import { moneyOutAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './CreditAmountCard.translations'
import MoneyoutAction from './components/MoneyoutAction/MoneyoutAction.vue'

const i18n = useI18n()

const { data, pending } = useHttpFetch(moneyOutAPI.getMoneyOutSalesAmount)
</script>

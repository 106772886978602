<template>
  <template v-if="tooltip">
    <RevTooltip>
      <template #trigger="{ show, hide }">
        <component
          :is="component"
          class="align-text-top"
          :label
          :variant
          @focusin="show"
          @focusout="hide"
          @mouseleave="hide"
          @mouseover="show"
        />
      </template>
      {{ tooltip }}
    </RevTooltip>
  </template>
  <template v-else>
    <component :is="component" :label :variant />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { moneyOutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBadge } from '@ds/components/Badge'
import { VariantsEnum } from '@ds/components/Badge/Badge.constant'
import { RevTag } from '@ds/components/Tag'
import { RevTooltip } from '@ds/components/Tooltip'

import translation from './MyOtherThingTag.translations'

type Props = {
  moneyOut: moneyOutAPI.MoneyOut
  type?: 'tag' | 'badge'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'tag',
})

const i18n = useI18n()

const component = computed(() => (props.type === 'tag' ? RevTag : RevBadge))

const variant = computed(() => {
  switch (props.moneyOut.state) {
    case moneyOutAPI.State.NEW:
    case moneyOutAPI.State.STORED:
      return VariantsEnum.Info
    case moneyOutAPI.State.CONFIRMED_WAITING_NOTIF:
      return VariantsEnum.Warning
    case moneyOutAPI.State.COMPLETED:
      return VariantsEnum.Success
    default:
      return VariantsEnum.Danger
  }
})

const label = computed(() => {
  switch (props.moneyOut.state) {
    case moneyOutAPI.State.NEW:
      return i18n(translation.paymentNew)
    case moneyOutAPI.State.CONFIRMED_WAITING_NOTIF:
      return i18n(translation.paymentMissingInfo)
    case moneyOutAPI.State.STORED:
      return i18n(translation.paymentOngoing)
    case moneyOutAPI.State.COMPLETED:
      return i18n(translation.paymentDone)
    default:
      return i18n(translation.paymentFailed)
  }
})

const tooltip = computed(() => {
  switch (props.moneyOut.state) {
    case moneyOutAPI.State.CONFIRMED_WAITING_NOTIF:
      return i18n(translation.paymentMissingInfoTooltip)
    case moneyOutAPI.State.NEW:
    case moneyOutAPI.State.STORED:
    case moneyOutAPI.State.COMPLETED:
    default:
      return null
  }
})
</script>

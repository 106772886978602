export default {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  title: {
    id: 'dashboard_others_title',
    defaultMessage: 'My others things',
  },
  subtitle: {
    id: 'dashboard_others_subtitle',
    defaultMessage: '',
  },
  listOfOperations: {
    id: 'dashboard_others_list_of_operations',
    defaultMessage: 'List of operations',
  },
}

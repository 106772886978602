export default {
  transferInfo: {
    id: 'dashboard_others_transfer_info',
    defaultMessage: 'Transfer of {amount}',
  },
  hyperwalletLinkRedirection: {
    id: 'dashboard_others_hyperwallet_link_redirection',
    defaultMessage: 'Manage your payment on Hyperwallet Payportal',
  },
}

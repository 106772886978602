export default {
  title: {
    id: 'dashboard_others_modal_redirect_title',
    defaultMessage: "Let's finish up your refund",
  },
  content: {
    id: 'dashboard_others_modal_redirect_content',
    defaultMessage:
      "To transfer your money, we're going to send you to our trusted friends at Hyperwallet. They're a third-party site, so their content and privacy practices are completely independent from ours at Back Market. If you're curious, take a look at their privacy policy once you're there.",
  },
  confirmAction: {
    id: 'dashboard_others_modal_redirect_confirm_action',
    defaultMessage: 'Go to Hyperwallet',
  },
  cancelAction: {
    id: 'dashboard_others_modal_redirect_cancel_action',
    defaultMessage: 'Stay on Back Market',
  },
}

<template>
  <RevContainer>
    <div class="mb-32 space-y-4 text-center">
      <h2 class="heading-1">{{ i18n(translations.title) }}</h2>

      <p class="body-1">{{ i18n(translations.subtitle) }}</p>
    </div>

    <CreditAmountCard class="mb-32" />

    <h3 class="heading-2 mb-16">{{ i18n(translations.listOfOperations) }}</h3>

    <MyOtherThingsList />
  </RevContainer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import translations from './MyOtherThings.translations'
import CreditAmountCard from './components/CreditAmountCard/CreditAmountCard.vue'
import MyOtherThingsList from './components/MyOtherThingsList/MyOtherThingsList.vue'

const i18n = useI18n()
</script>

<template>
  <div v-if="hasLink">
    <RevLink external :href="`/dashboard/others/moneyouts/${moneyOut.id}`">
      {{ i18n(translation.hyperwalletLinkRedirection) }}
    </RevLink>
  </div>

  <RevIllustration
    v-if="moneyOut.psp === moneyOutAPI.MoneyOutPsp.ADYEN_PIPO"
    alt="Adyen"
    :height="25"
    :src="'/img/payment/adyen.svg'"
    :width="50"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { moneyOutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import translation from '~/scopes/dashboard/my-other-things/components/MyOtherThingsList/components/MyOtherThingsListItem/MyOtherThingsListItem.translations'

type Props = {
  moneyOut: moneyOutAPI.MoneyOut
}

const props = defineProps<Props>()

const i18n = useI18n()

const hasLink = computed(() => {
  const { state, psp } = props.moneyOut

  const hasSuccessState =
    state === moneyOutAPI.State.NEW ||
    state === moneyOutAPI.State.STORED ||
    state === moneyOutAPI.State.CONFIRMED_WAITING_NOTIF ||
    state === moneyOutAPI.State.COMPLETED

  const hasPayportalProvider =
    psp === moneyOutAPI.MoneyOutPsp.HYPERWALLET_PAYPORTAL

  return hasPayportalProvider && hasSuccessState
})
</script>

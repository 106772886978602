export default {
  paymentNew: {
    id: 'dashboard_others_payment_new_tag',
    defaultMessage: 'new',
  },
  paymentMissingInfo: {
    id: 'dashboard_others_payment_missing_information_tag',
    defaultMessage: 'missing information',
  },
  paymentMissingInfoTooltip: {
    id: 'dashboard_others_payment_missing_information_tooltip',
    defaultMessage: 'You must add your bank information on hyperwallet.',
  },
  paymentOngoing: {
    id: 'dashboard_others_payment_ongoing_tag',
    defaultMessage: 'on going',
  },
  paymentDone: {
    id: 'dashboard_others_payment_done_tag',
    defaultMessage: 'done',
  },
  paymentFailed: {
    id: 'dashboard_others_payment_failed_tag',
    defaultMessage: 'failed',
  },
}

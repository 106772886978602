<template>
  <RevButton
    :variant="VariantsEnum.Primary"
    @click="openModal(MODAL_NAMES.MONEYOUT_REDIRECT)"
  >
    {{ i18n(translations.moneyoutAction) }}
  </RevButton>
  <MoneyoutModalRedirect />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { VariantsEnum } from '@ds/components/Button/Button.constant'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from './MoneyoutAction.constant'
import translations from './MoneyoutAction.translations'
import MoneyoutModalRedirect from './components/MoneyoutModalRedirect/MoneyoutModalRedirect.vue'

const i18n = useI18n()
</script>

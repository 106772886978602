import type { Price } from '../../api-models'

export const State = {
  NEW: 'New',
  STORED: 'Stored',
  CONFIRMED_WAITING_NOTIF: 'Confirmed Waiting notif',
  FAILED_REQUEST: 'Failed Request',
  FAILED_CONFIRM: 'Failed Confirm',
  FAILED_NOTIF: 'Failed Notif',
  COMPLETED: 'Completed',
  EXPIRED_DECLINED: 'Expired / Declined',
} as const

export type StateEnum = (typeof State)[keyof typeof State]

export const MoneyOutPsp = {
  ADYEN_PIPO: 'ADYEN_PIPO',
  HYPERWALLET_PAYPORTAL: 'HYPERWALLET_PAYPORTAL',
} as const

export type MoneyOutPspType = (typeof MoneyOutPsp)[keyof typeof MoneyOutPsp]

export type MoneyOut = {
  id: string
  dateCreation: string
  datePayment: null | string
  state: null | StateEnum
  amount: Price
  psp: MoneyOutPspType
}

export type MoneyOutSalesAmount = {
  amount: Price
  bic: string
  iban: string
}

export type PostMoneyOutResponse = {
  payportalLink: string
}
